import React, { useEffect, useState } from 'react';
import { Typography, Box, Icon, List, Item } from '@worthy-npm/worthy-common-ui-components';

import { Step, StepContainer, StepGridContainer } from '../../../styles/common';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectCondition, updateCondition } from '../../../slices/submitSlice';
import { StepProps } from '../common';
import StepTitle from '../../stepTitle';
import { parametrize } from '../../../lib/commonUtils';
import GA from '../../../data/GA';
import { WatchConditions } from '../../../data/ItemDetails';
import { StyledButton, StyledList } from '../../common/ButtonsList';

function StepCondition({
  next,
  active,
  idx,
  stepName,
  prev,
  title,
  stepCaption,
  props,
}: StepProps) {
  const dispatch = useAppDispatch();
  const condition = useAppSelector(selectCondition);

  const [busy, setBusy] = useState(false);
  useEffect(() => {
    if (!active) return;
    GA.stepView(stepName);
  }, [active]);

  return (
    <StepContainer data-automation="condition-step">
      <Step>
        <StepTitle
          stepCaption={stepCaption}
          idx={idx}
          prev={prev}
          stepTitle={title || 'What condition is your watch in?'}
          stepName={stepName}
          stepNotice="Select an option below"
          optionalProps={props || {}}
        />

        <StepGridContainer>
          <StyledList>
            {WatchConditions.map((item) => (
              <StyledButton
                data-automation={`condition-step-item-${parametrize(item.code)}`}
                key={item.code}
                onClick={(e) => {
                  if (busy) return;
                  setBusy(true);
                  GA.select({ step: stepName, value: item.code });
                  dispatch(updateCondition(item.code));
                  setTimeout(() => {
                    next(e);
                    setTimeout(() => {
                      setBusy(false);
                    }, 600);
                  }, 600);
                }}
                // selected={item.code === condition}
              >
                <Typography variant="body1">{item.text}</Typography>
              </StyledButton>
            ))}
          </StyledList>
        </StepGridContainer>
      </Step>
    </StepContainer>
  );
}

export default StepCondition;
