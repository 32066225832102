import React, { useEffect, useState } from 'react';
import { TextField, Alert, Typography } from '@worthy-npm/worthy-common-ui-components';
import {
  FormContentNarrow,
  Step,
  NextStepButton,
  StepContainer,
  StepGridContainer,
} from '../../../styles/common';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectItemType, updateDiamondNumber } from '../../../slices/submitSlice';
import { StepProps } from '../common';
import StepTitle from '../../stepTitle';
import GA from '../../../data/GA';
import StyledInfoAlert from '../../common/StyledInfoAlert';
import CTAButton from '../../common/ContinueButton';

function DiamondNumber({
  next,
  active,
  stepName,
  idx,
  prev,
  title,
  stepCaption,
  props,
}: StepProps) {
  const [diamondNumber, setDiamondNumber] = useState<number>(0);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [nextAvailability, setNextAvailability] = useState<boolean>(false);
  const [busy, setBusy] = useState(false);

  const itemType = useAppSelector(selectItemType);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!active) return;
    GA.stepView(stepName);
  }, [active]);

  const validate = (value: string): void => {
    const parsedValue = Number(value);

    if (Number.isNaN(parsedValue) || !Number.isInteger(parsedValue)) {
      setDiamondNumber(0);
      setIsValid(false);
      setNextAvailability(false);
      setValidationMessage('Error: Please enter an integer in the range from 0 to 100');
    } else if (parsedValue > 100) {
      setDiamondNumber(0);
      setIsValid(false);
      setNextAvailability(false);
      setValidationMessage('Error: Please enter a number smaller than 100');
    } else if (parsedValue < 1) {
      setDiamondNumber(0);
      setIsValid(false);
      setNextAvailability(false);
      setValidationMessage('Error: Please enter a number greater than 0');
    } else {
      setDiamondNumber(Math.trunc(parsedValue));
      setIsValid(true);
      setNextAvailability(true);
      setValidationMessage('');
    }
  };

  const handleEnter = (e: any) => {
    if (e.key === 'Enter') {
      validate(e.target.value);

      if (isValid && nextAvailability) {
        dispatch(updateDiamondNumber(diamondNumber));
        next(e);
      }
    }
  };

  return (
    <StepContainer data-automation="stone-number-step">
      <Step>
        <StepTitle
          idx={idx}
          stepCaption={stepCaption}
          prev={prev}
          stepTitle={title || `Great! How many diamonds are set in your ${itemType.toLowerCase()}?`}
          stepName={stepName}
          stepNotice={
            itemType === 'Earrings'
              ? 'Enter the total number of all diamonds in one earring'
              : 'Enter the total number below'
          }
          optionalProps={props || {}}
        />
        <StepGridContainer>
          <FormContentNarrow>
            <TextField
              autoFocus
              data-automation="stone-number-input"
              inputProps={{
                inputMode: 'numeric',
                type: 'number',
                step: 1,
                min: 1,
                max: 100,
              }}
              variant="standard"
              error={!isValid}
              placeholder="00"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                validate(e.target.value);
              }}
              onKeyDown={handleEnter}
              sx={{ input: { textAlign: 'center', minWidth: '10.3em' } }}
            />
          </FormContentNarrow>

          {!isValid && (
            <Alert style={{ marginTop: '20px' }} severity="error">
              <Typography>{validationMessage}</Typography>
            </Alert>
          )}

          <StyledInfoAlert sx={{ marginTop: 3 }}>
            Tip: You can give an approximate number if you’re not sure
          </StyledInfoAlert>

          <NextStepButton>
            <CTAButton
              data-automation="stone-number-next-button"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                if (diamondNumber > 0 && !busy) {
                  setBusy(true);
                  GA.submitNext(stepName, diamondNumber.toString());
                  dispatch(updateDiamondNumber(diamondNumber));
                  next(e);
                  setTimeout(() => {
                    setBusy(false);
                  }, 150);
                }
              }}
              disabled={!nextAvailability}
            />
          </NextStepButton>
        </StepGridContainer>
      </Step>
    </StepContainer>
  );
}

export default DiamondNumber;
