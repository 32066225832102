import React, { useState, ChangeEvent } from 'react';
import {
  Box,
  Button,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Checkbox,
  Link,
} from '@worthy-npm/worthy-common-ui-components';
import styled from '@emotion/styled/macro';
import CashForGoldAPI, { CashForGoldFields } from '../services/cashForGoldAPI';
import WorthyAPI from '../services/worthyAPI';
import { CASH_FOR_GOLD_TERMS_AND_CONDITIONS_LINK, USA_STATES } from '../data/constants';
import GA from '../data/GA';

const ErrorTypography = styled(Typography)`
  font-size: 12px;
  color: #de1c22;
  font-weight: 500;
  text-align: left;
  font-family: Roboto, Playfair, sans-serif;
  line-height: 1.66;
  margin: 3px 14px 0;
`;

const FromTitle = styled(Typography)`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

interface CashForGoldFormProps {
  handleCFGFormSubmit: () => void;
}

export default function CashForGoldForm({ handleCFGFormSubmit }: CashForGoldFormProps) {
  const [formValues, setFormValues] = useState<CashForGoldFields>({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
    apartment: '',
    city: '',
    zip: '',
    state: '',
    country: '',
    sms: false,
    termsAndConditions: false,
    shippingMethod: '',
  });
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
    apartment: '',
    city: '',
    zip: '',
    state: '',
    country: '',
    termsAndConditions: '',
    shippingMethod: '',
  });
  const [submitError, setSubmitError] = useState('');

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name as string]: value as string,
    });
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/g, ''); // Remove all non-numeric characters

    if (value.length > 6) {
      value = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;
    } else if (value.length > 3) {
      value = `${value.slice(0, 3)}-${value.slice(3, 6)}`;
    }

    setFormValues({
      ...formValues,
      phoneNumber: value,
    });
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: checked,
    });
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;
    return phoneRegex.test(phoneNumber);
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      address: '',
      apartment: '',
      city: '',
      zip: '',
      state: '',
      country: '',
      termsAndConditions: '',
      shippingMethod: '',
    };

    if (!formValues.firstName) {
      newErrors.firstName = 'First name is required';
      isValid = false;
    }
    if (!formValues.lastName) {
      newErrors.lastName = 'Last name is required';
      isValid = false;
    }
    if (!formValues.email) {
      newErrors.email = 'Email is required';
      isValid = false;
    } else if (!validateEmail(formValues.email)) {
      newErrors.email = 'Invalid email address';
      isValid = false;
    }
    if (!formValues.phoneNumber) {
      newErrors.phoneNumber = 'Phone number is required';
      isValid = false;
    } else if (!validatePhoneNumber(formValues.phoneNumber)) {
      newErrors.phoneNumber = 'Phone number must be in format XXX-XXX-XXXX';
      isValid = false;
    }
    if (!formValues.address) {
      newErrors.address = 'Address is required';
      isValid = false;
    }
    if (!formValues.city) {
      newErrors.city = 'City is required';
      isValid = false;
    }
    if (!formValues.zip) {
      newErrors.zip = 'Zip code is required';
      isValid = false;
    }
    if (!formValues.state) {
      newErrors.state = 'State is required';
      isValid = false;
    }
    if (!formValues.country) {
      newErrors.country = 'Country is required';
      isValid = false;
    } else if (formValues.country !== 'US' && formValues.country !== 'CA') {
      newErrors.country = 'Country must be US or CA';
      isValid = false;
    }
    if (!formValues.termsAndConditions) {
      newErrors.termsAndConditions = 'You must agree to the terms and conditions';
      isValid = false;
    }
    if (!formValues.shippingMethod) {
      newErrors.shippingMethod = 'Shipping method is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const saveToDB = async () => {
    let ip = '';
    try {
      const res = await WorthyAPI.storeCashForGoldInBackend(formValues);
      ip = res?.data?.userIp;
    } catch (error) {
      window?.rollbar?.error(`storeCashForGoldInBackend: ${error}`);
      // todo add sensing user ip in case of an error as well
    }

    return ip;
  };

  const submitForm = async (ip: string) => {
    let successfulSubmit = false;
    try {
      const res = await CashForGoldAPI.submitDataToCashForGold(formValues, ip);
      const parsedData = JSON.parse(res.data.replace(/'/g, '"').replace(/(\w+):/g, '"$1":'));
      const { success, msg } = parsedData;
      if (res.status === 200 && success) {
        GA.successSubmitForCashForGold();
        successfulSubmit = true;
      }
      console.log(`submitDataToCashForGold: ${msg}`);
    } catch (error) {
      window?.rollbar?.error(`submitDataToCashForGold: ${error}`);
      setSubmitError('An error occurred while submitting the form. Please try again later.');
    }
    return successfulSubmit;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const formIsValid = validateForm();
    if (formIsValid) {
      const ip = await saveToDB();
      const successfulSubmit = await submitForm(ip);
      if (successfulSubmit) {
        handleCFGFormSubmit();
      }
    }
  };

  return (
    <Box sx={{ padding: '0' }}>
      <Box
        sx={{
          border: '1px solid rgba(58, 95, 227, 0.5)',
          borderRadius: '16px',
          padding: 6,
          mt: 6,
          backgroundColor: '#f6f8ff',
        }}
      >
        <FromTitle>How do you want to ship your item(s)?</FromTitle>
        <RadioGroup name="shippingMethod" onChange={handleInputChange}>
          <FormControlLabel
            value="usps"
            control={<Radio size="small" />}
            label={<Typography sx={{ fontSize: '16px' }}>USPS Mailer (Fast)</Typography>}
          />
          <FormControlLabel
            value="fedex"
            control={<Radio size="small" />}
            label={<Typography sx={{ fontSize: '16px' }}>FedEx Label (Fastest)</Typography>}
          />
        </RadioGroup>
        {errors.shippingMethod && <ErrorTypography>{errors.shippingMethod}</ErrorTypography>}
        <FromTitle sx={{ mt: 4, mb: 2 }}>Contact Information</FromTitle>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="firstName"
              label="First Name"
              name="firstName"
              InputLabelProps={{
                sx: { fontSize: '0.875rem' },
              }}
              value={formValues.firstName}
              onChange={handleInputChange}
              error={!!errors.firstName}
              helperText={errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              InputLabelProps={{
                sx: { fontSize: '0.875rem' },
              }}
              value={formValues.lastName}
              onChange={handleInputChange}
              error={!!errors.lastName}
              helperText={errors.lastName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              InputLabelProps={{
                sx: { fontSize: '0.875rem' },
              }}
              value={formValues.email}
              onChange={handleInputChange}
              error={!!errors.email}
              helperText={errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="phoneNumber"
              label="Phone Number"
              name="phoneNumber"
              InputLabelProps={{
                sx: { fontSize: '0.875rem' },
              }}
              inputProps={{ maxLength: 12 }}
              value={formValues.phoneNumber}
              onChange={handlePhoneNumberChange}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="address"
              label="Address"
              name="address"
              InputLabelProps={{
                sx: { fontSize: '0.875rem' },
              }}
              value={formValues.address}
              onChange={handleInputChange}
              error={!!errors.address}
              helperText={errors.address}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="apartment"
              label="Apt/Unit#"
              name="apartment"
              InputLabelProps={{
                sx: { fontSize: '0.875rem' },
              }}
              value={formValues.apartment}
              onChange={handleInputChange}
              error={!!errors.apartment}
              helperText={errors.apartment}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="city"
              label="City"
              name="city"
              InputLabelProps={{
                sx: { fontSize: '0.875rem' },
              }}
              value={formValues.city}
              onChange={handleInputChange}
              error={!!errors.city}
              helperText={errors.city}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="zip"
              label="Zip/Postal Code"
              name="zip"
              InputLabelProps={{
                sx: { fontSize: '0.875rem' },
              }}
              value={formValues.zip}
              onChange={handleInputChange}
              error={!!errors.zip}
              helperText={errors.zip}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!errors.state}>
              <InputLabel id="select-label-state" sx={{ fontSize: '0.875rem' }}>
                State
              </InputLabel>
              <Select
                required
                labelId="select-label-state"
                id="state"
                value={formValues.state}
                label="State"
                name="state"
                onChange={handleInputChange}
                sx={{ fontSize: '0.875rem' }}
              >
                {USA_STATES.map(([stateName, stateCode]) => (
                  <MenuItem key={stateCode} value={stateCode}>
                    {stateName}
                  </MenuItem>
                ))}
              </Select>
              {errors.state && <ErrorTypography>{errors.state}</ErrorTypography>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!errors.country}>
              <InputLabel id="select-label-country" sx={{ fontSize: '0.875rem' }}>
                Country
              </InputLabel>
              <Select
                required
                labelId="select-label-country"
                id="country"
                value={formValues.country}
                label="Country"
                name="country"
                onChange={handleInputChange}
                sx={{ fontSize: '0.875rem' }}
              >
                <MenuItem value="US">USA</MenuItem>
                <MenuItem value="CA">Canada</MenuItem>
              </Select>
              {errors.country && <ErrorTypography>{errors.country}</ErrorTypography>}
            </FormControl>
          </Grid>
        </Grid>
        <FromTitle sx={{ mt: 4, mb: 6 }} />
        <FormControlLabel
          control={
            <Checkbox
              checked={formValues.sms}
              onChange={handleCheckboxChange}
              name="sms"
              color="primary"
              size="small"
            />
          }
          label={
            <Typography sx={{ fontSize: '16px', paddingBottom: '5px' }}>
              Yes, TEXT ME updates about my shipment. Std. msg & data rates apply.
            </Typography>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formValues.termsAndConditions}
              onChange={handleCheckboxChange}
              name="termsAndConditions"
              color="primary"
              size="small"
            />
          }
          label={
            <Typography sx={{ fontSize: '16px' }}>
              I agree to Cash For Gold USA&apos;s{' '}
              <Link href={CASH_FOR_GOLD_TERMS_AND_CONDITIONS_LINK} target="_blank">
                terms and conditions
              </Link>
              .*
            </Typography>
          }
        />
        {errors.termsAndConditions && (
          <ErrorTypography>{errors.termsAndConditions}</ErrorTypography>
        )}
        <Button
          fullWidth
          variant="contained"
          size="large"
          disableElevation
          sx={{ height: '52px', padding: 0, maxWidth: '320px', marginTop: '40px' }}
          onClick={handleSubmit}
        >
          <Typography variant="button" textTransform="none">
            Submit to Cash for Gold USA
          </Typography>
        </Button>
        {submitError && <ErrorTypography>{submitError}</ErrorTypography>}

        <Typography
          variant="body2"
          marginTop="12px"
          fontSize="14px"
          color="#7A8994"
          maxWidth="320px"
        >
          By clicking Submit, you acknowledge that your information will be shared with CJ
          Environmental, Inc., a third party, and agree to the Cash For Gold USA{' '}
          <Link href={CASH_FOR_GOLD_TERMS_AND_CONDITIONS_LINK} target="_blank">
            terms and conditions
          </Link>
          .
        </Typography>
      </Box>
    </Box>
  );
}
