export enum CLASSIFICATION {
  NATURAL = 'Natural',
  LAB_GROWN = 'Lab grown',
  GEMSTONE = 'Gemstone',
}

export const ItemTypes = {
  Ring: 'ring',
  Bracelet: 'bracelet',
  LooseDiamond: 'loose diamond',
  Earrings: 'earrings',
  Necklace: 'necklace',
  JewelryBox: 'jewelry box',
  Watch: 'watch',
};

export const ItemTypesPlural = {
  Ring: 'Rings',
  Bracelet: 'Bracelets',
  'Loose Diamond': 'Loose Diamonds',
  Necklace: 'Necklaces',
  'Jewelry Box': 'Jewelry Boxes',
  Watch: 'Watches',
  Earrings: 'earrings',
};

export const Styles = {
  Solitaire: 'solitaire',
  BridalSet: 'bridal set',
  ThreeStone: '3 stone',
  Halo: 'halo',
  Drop: 'drop',
  Pendant: 'pendant',
  Link: 'link',
  Choker: 'choker',
  Collar: 'collar',
  Lariat: 'lariat',
  Riviera: 'riviera',
  Station: 'station',
  Tennis: 'Tennis',
  Charm: 'charm',
  Slide: 'slide',
  Bangle: 'bangle',
  Other: 'Other',
};

export const MetalTypes = {
  Platinum: 'Platinum',
  K22: '22k',
  K18: '18k',
  K14: '14k',
  Silver: 'Silver',
  Under10K: '10k or less',
  Other: 'Other',
};

export const DiamondClarity = {
  FL: 'fl',
  IF: 'if',
  VVS1: 'vvs1',
  VVS2: 'vvs2',
  VS1: 'vs1',
  VS2: 'vs2',
  SI1: 'si1',
  SI2: 'si2',
  I1: 'i1',
  I2: 'i2',
  I3: 'i3',
  NotSure: 'not sure',
};

export const DiamondColor = {
  D: 'd',
  E: 'e',
  F: 'f',
  G: 'g',
  H: 'h',
  I: 'i',
  J: 'j',
  K: 'k',
  L: 'l',
  MtoZ: 'm-z',
  Fancy: 'fancy',
  NotSure: 'not sure',
};

export const GemstoneColor = {
  Black: 'black',
  Colorless: 'colorless',
  Purple: 'purple',
  Blue: 'blue',
  Brown: 'brown',
  Gray: 'gray',
  Green: 'green',
  Orange: 'orange',
  Pink: 'pink',
  Red: 'red',
  White: 'white',
  Yellow: 'yellow',
  Multicolored: 'multicolored',
  NA: 'na',
};

export const DiamondShape = {
  Round: 'round',
  Princess: 'princess',
  Oval: 'oval',
  Marquise: 'marquise',
  Cushion: 'cushion',
  Pear: 'pear',
  Emerald: 'emerald',
  Other: 'other',
};

export const EarringsStyle = {
  Stud: 'Stud',
  Jacket: 'Jacket',
  CentralCluster: 'Central Cluster',
  BlossomCluster: 'Blossom Cluster',
  Chandelier: 'Chandelier',
  Hoops: 'Hoops',
  Huggie: 'Huggie',
  Drop: 'Drop',
  Other: 'Other',
};

export const BraceletStyle = {
  Tennis: 'Tennis',
  Charm: 'Charm',
  Bangle: 'Bangle',
  Slide: 'Slide',
  Link: 'Link',
  Other: 'Other',
};

export const NecklaceStyle = {
  Link: 'Link',
  Choker: 'Choker',
  Collar: 'Collar',
  Pendant: 'Pendant',
  Riviera: 'Riviera',
  Station: 'Station',
  Drop: 'Drop',
  Lariat: 'Lariat',
  Other: 'Other',
};

export const WatchModel = {
  // rolex
  dayDate: 'Day Date',
  datejust: 'Datejust',
  oysterPerpetual: 'Oyster Perpetual',
  daytona: 'Daytona',
  submariner: 'Submariner',
  gmtMaster: 'GMT Master ||',
  yachtMaster: 'Yacht-Master',
  seaDweller: 'Sea-Dweller',
  skyDweller: 'Sky-Dweller',
  // patek
  naulitius: 'Nautilius',
  aquanaut: 'Aquanaut',
  calatrava: 'Calatrava',
  grandComplications: 'Grand Complications',
  gondolo: 'Gondolo',
  twenty: 'Twenty~4',
  goldenEllipse: 'Golden Ellipse',
  complications: 'Complications',
  officers: 'Officers',
  travelTime: 'Travel Time',
  // omega
  seamaster: 'Seamaster',
  speedmaster: 'Speedmaster',
  constellation: 'Constellation',
  deVille: 'De Ville',
  aquaTerra: 'Aqua Terra',
  seamasterPlanetOcean: 'Seamaster Planet Ocean',
  seamasterDriver: 'Seamaster Diver 300M',
  seamasterRailmaster: 'Seamaster Railmaster',
  seamasterBullhead: 'Seamaster Bullhead',
  seamasterPloprof: 'Seamaster Ploprof',
  // iwc
  portugieser: 'Portugieser',
  pilotsWatch: "Pilot's watch",
  portofino: 'Portofino',
  aquatimer: 'Aquatimer',
  ingenieur: 'Ingenieur',
  daVinci: 'Da Vinci',
  bigPilotsWatch: `Big Pilot's Watch`,
  spitfire: 'Spitfire',
  topGun: 'Top Gun',
  fliegerchronograph: 'Fliegerchronograph',

  // cartier
  santosDeCartier: 'Santos de Cartier',
  tank: 'Tank',
  ballonBleuDeCartier: 'Ballon Bleu de Cartier',
  calibreDeCartier: 'Calibre de Cartier',
  driveDeCartier: 'Drive de Cartier',
  pashaDeCartier: 'Pasha de Cartier',
  rondeDeCartier: 'Ronde de Cartier',
  baignoire: 'Baignoire',
  cleDeCartier: 'Cle de Cartier',
  rotondDeCartier: 'Rotonde de Cartier',

  // breitling
  navitimer: 'Navitimer',
  superocean: 'Superocean',
  avenger: 'Avenger',
  chronomat: 'Chronomat',
  colt: 'Colt',
  transocean: 'Transocean',
  galactic: 'Galactic',
  premier: 'Premier',
  professional: 'Professional',
  superoceanHeritage: 'Superocean Heritage',
  Other: 'Other',

  //   audemarsPiguet
  royalOak: 'Royal Oak',
  royalOakOffshore: 'Royal Oak Offshore',
  royalOakConcept: 'Royal Oak Concept',
  millenary: 'Millenary',
  julesAudemars: 'Jules Audemars',
  code: 'Code 11.59',
  edwardPiguet: 'Edward Piguet',
  hauteJoaillerie: 'Haute Joaillerie',
  promesse: 'Promesse',
  tradition: 'Tradition',

  // richardMille
  rm011: 'RM 011',
  rm035: 'RM 035',
  rm027: 'RM 027',
  rm055: 'RM 055',
  rm010: 'RM 010',
  rm029: 'RM 029',
  rm030: 'RM 030',
  rm052: 'RM 052',
  rm5003: 'RM 50-03',
  rm016: 'RM 016',

  // paneral
  luminor: 'Luminor',
  radiomir: 'Radiomir',
  submersible: 'Submersible',
  mareNostrum: 'Mare Nostrum',
  luminorDue: 'Luminor Due',
  luminorMarina: 'Luminor Marina',
  luminorSubmersible: 'Luminor Submersible',
  luminor1950: 'Luminor 1950',
  radiomir1940: 'Radiomir 1940',
  luminorGMT: 'Luminor GMT',

  // breguel
  classique: 'Classique',
  marine: 'Marine',
  typeXX: 'Type XX',
  reineDeNaples: 'Reine de Naples',
  heritage: 'Heritage',
  laTraditionBreguet: 'La Tradition Breguet',
  classiqueComplications: 'Classique Complications',
  breguetClassiqueDame: 'Breguet Classique Dame',
  classiqueGrandesComplications: 'Classique Grandes Complications',

  // vacheronConstantin
  overseas: 'Overseas',
  patrimony: 'Patrimony',
  traditionnelle: 'Traditionnelle',
  historiques: 'Historiques',
  fiftysix: 'Fiftysix',
  quai: 'Quai de l’Île',
  harmony: 'Harmony',
  malte: 'Malte',
  dart: 'Métiers d’Art',
  egerie: 'Egerie',

  // chopard
  happyDiamonds: 'Happy Diamonds',
  luc: 'L.U.C',
  imperiale: 'Imperiale',
  milleMiglia: 'Mille Miglia',
  alpineEagle: 'Alpine Eagle',
  happySport: 'Happy Sport',
  classicRacing: 'Classic Racing',
  classic: 'Classic',
  lucXP: 'L.U.C. XP',
  lucPerpetual: 'L.U.C. Perpetual',
};

export const WatchBandMaterial = {
  leather: 'Leather',
  rubber: 'Rubber',
  snake: 'Snake',
  stones: '3 Stones',
  stainlessSteel: 'Stainless Steel',
  steelNGold: 'Steel and Gold',
  yellowGold: 'Yellow Gold',
  rodeGold: 'Rose Gold',
};

export const WatchCaseMaterial = {
  stainlessSteel: 'Stainless Steel',
  steelNGold: 'Steel and Gold',
  yellowGold: 'Yellow Gold',
  stones: '3 Stones',
  roseGold: 'Rose Gold',
  whiteGold: 'White Gold',
  goldFilled: 'Gold Filled/Plated',
  platinum: 'Platinum',
};

export const Subsets = {
  CenterStone: 'diamond_center_stone',
  MinorStoneBatch: 'diamond_minor_stone_batch',
  Earrings: 'jewelry_earrings',
  Rings: 'jewelry_rings',
  Watch: 'luxury_watch_watches',
};

export const SubsetForEarringsStyle = {
  [EarringsStyle.Stud]: Subsets.CenterStone,
  [EarringsStyle.Jacket]: Subsets.MinorStoneBatch,
  [EarringsStyle.CentralCluster]: Subsets.CenterStone,
  [EarringsStyle.BlossomCluster]: Subsets.MinorStoneBatch,
  [EarringsStyle.Chandelier]: Subsets.MinorStoneBatch,
  [EarringsStyle.Hoops]: Subsets.MinorStoneBatch,
  [EarringsStyle.Huggie]: Subsets.MinorStoneBatch,
  [EarringsStyle.Drop]: Subsets.CenterStone,
  [EarringsStyle.Other]: Subsets.CenterStone,
};

export const SubsetForNecklaceStyle = {
  [NecklaceStyle.Link]: Subsets.MinorStoneBatch,
  [NecklaceStyle.Choker]: Subsets.MinorStoneBatch,
  [NecklaceStyle.Collar]: Subsets.MinorStoneBatch,
  [NecklaceStyle.Pendant]: Subsets.CenterStone,
  [NecklaceStyle.Riviera]: Subsets.MinorStoneBatch,
  [NecklaceStyle.Station]: Subsets.MinorStoneBatch,
  [NecklaceStyle.Lariat]: Subsets.MinorStoneBatch,
  [NecklaceStyle.Drop]: Subsets.CenterStone,
  [EarringsStyle.Other]: Subsets.CenterStone,
};

export const JewelryBrand = {
  Tiffany: 'tiffany co',
  Cartier: 'cartier',
  VanCleefArpens: 'van cleef arpels',
  HarryWinston: 'harry winston',
  Bvlgari: 'bvlgari',
  DavidYurman: 'david yurman',
  Graff: 'graff',
  Chopard: 'chopard',
  Rolex: 'rolex',
  PatekPhilippe: 'patek philippe',
  Breitling: 'breitling',
  Omega: 'omega',
  Iwc: 'iwc',
  TagHeuer: 'tag heuer',
  vca: 'vca',
  DavidWebb: 'davidWebb',
  Verdura: 'verdura',
  Buccellati: 'buccellati',
  OscarHeyman: 'oscarHeyman',
  Other: 'other',
};

export const WatchBrand = {
  Rolex: 'rolex',
  PatekPhilippe: 'patekPhilippe',
  Breitling: 'breitling',
  Omega: 'omega',
  Cartier: 'cartier',
  Iwc: 'iwc',
  TagHeuer: 'tagHeuer',
  AudemarsPiguet: 'audemarsPiguet',
  RichardMille: 'richardMille',
  Paneral: 'paneral',
  VacheronConstantin: 'vacheronConstantin',
  Breguel: 'breguel',
  Chopard: 'chopard',
  Other: 'other',
};

export const CertificateType = {
  None: 'none',
  GIA: 'gia',
  Other: 'other',
};

export const EarringsCertificateType = {
  GIA: 'gia',
  IGI: 'igi',
  GSI: 'gsi',
  Other: 'other',
  None: 'none',
};

export const WatchCondition = {
  newInBox: 'new in box',
  excellent: 'excellent',
  veryGood: 'very good',
  good: 'good',
  fair: 'fair',
  nowWorking: 'not working',
};

export const WatchGender = {
  man: 'man',
  woman: 'woman',
  unisex: 'unisex',
};

export const JeweleryItemsList = [
  {
    img: '/images/icons/brands/tiffany.png',
    code: JewelryBrand.Tiffany,
  },
  {
    img: '/images/icons/brands/cartier.png',
    code: JewelryBrand.Cartier,
  },
  {
    img: '/images/icons/brands/vca.png',
    code: JewelryBrand.VanCleefArpens,
  },
  {
    img: '/images/icons/brands/davidWebb.png',
    code: JewelryBrand.DavidWebb,
  },
  {
    img: '/images/icons/brands/verdura.png',
    code: JewelryBrand.Verdura,
  },
  {
    img: '/images/icons/brands/buccellati.png',
    code: JewelryBrand.Buccellati,
  },
  {
    img: '/images/icons/brands/oscarHeyman.png',
    code: JewelryBrand.OscarHeyman,
  },
  {
    img: '/images/icons/brands/harry_winston.png',
    code: JewelryBrand.HarryWinston,
  },
  {
    img: '/images/icons/brands/graff.png',
    code: JewelryBrand.Graff,
  },
  {
    img: '/images/icons/brands/bvlgari.png',
    code: JewelryBrand.Bvlgari,
  },
  {
    text: JewelryBrand.Other,
    code: JewelryBrand.Other,
  },
];

export const WatchBrandItems = [
  {
    img: '/images/icons/brands/rolex.png',
    code: WatchBrand.Rolex,
  },

  {
    img: '/images/icons/brands/breitling.png',
    code: WatchBrand.Breitling,
  },
  {
    img: '/images/icons/brands/cartierWatch.png',
    code: WatchBrand.Cartier,
  },
  {
    img: '/images/icons/brands/iwc.png',
    code: WatchBrand.Iwc,
  },
  {
    img: '/images/icons/brands/omega.png',
    code: WatchBrand.Omega,
  },
  {
    img: '/images/icons/brands/patekPhilippe.png',
    code: WatchBrand.PatekPhilippe,
  },
  {
    img: '/images/icons/brands/audemarsPiguet.png',
    code: WatchBrand.AudemarsPiguet,
  },
  {
    img: '/images/icons/brands/richardMille.png',
    code: WatchBrand.RichardMille,
  },
  {
    img: '/images/icons/brands/paneral.png',
    code: WatchBrand.Paneral,
  },
  {
    img: '/images/icons/brands/breguel.png',
    code: WatchBrand.Breguel,
  },
  {
    img: '/images/icons/brands/vacheronConstantin.png',
    code: WatchBrand.VacheronConstantin,
  },
  {
    img: '/images/icons/brands/chopard.png',
    code: WatchBrand.Chopard,
  },
  {
    text: WatchBrand.Other,
    code: WatchBrand.Other,
  },
];

export const ModelByBrand = {
  rolex: [
    WatchModel.dayDate,
    WatchModel.datejust,
    WatchModel.oysterPerpetual,
    WatchModel.daytona,
    WatchModel.submariner,
    WatchModel.gmtMaster,
    WatchModel.yachtMaster,
    WatchModel.seaDweller,
    WatchModel.skyDweller,
    WatchModel.Other,
  ],
  breitling: [
    WatchModel.navitimer,
    WatchModel.superocean,
    WatchModel.avenger,
    WatchModel.chronomat,
    WatchModel.colt,
    WatchModel.transocean,
    WatchModel.premier,
    WatchModel.professional,
    WatchModel.galactic,
    WatchModel.superoceanHeritage,
    WatchModel.Other,
  ],
  cartier: [
    WatchModel.santosDeCartier,
    WatchModel.tank,
    WatchModel.ballonBleuDeCartier,
    WatchModel.calibreDeCartier,
    WatchModel.driveDeCartier,
    WatchModel.pashaDeCartier,
    WatchModel.rondeDeCartier,
    WatchModel.baignoire,
    WatchModel.cleDeCartier,
    WatchModel.rotondDeCartier,
    WatchModel.Other,
  ],
  iwc: [
    WatchModel.aquatimer,
    WatchModel.daVinci,
    WatchModel.ingenieur,
    WatchModel.pilotsWatch,
    WatchModel.portofino,
    WatchModel.portugieser,
    WatchModel.bigPilotsWatch,
    WatchModel.spitfire,
    WatchModel.topGun,
    WatchModel.fliegerchronograph,
    WatchModel.Other,
  ],
  omega: [
    WatchModel.constellation,
    WatchModel.seamaster,
    WatchModel.speedmaster,
    WatchModel.deVille,
    WatchModel.aquaTerra,
    WatchModel.seamasterPlanetOcean,
    WatchModel.seamasterDriver,
    WatchModel.seamasterRailmaster,
    WatchModel.seamasterBullhead,
    WatchModel.seamasterPloprof,
    WatchModel.Other,
  ],
  patekPhilippe: [
    WatchModel.naulitius,
    WatchModel.aquanaut,
    WatchModel.calatrava,
    WatchModel.grandComplications,
    WatchModel.gondolo,
    WatchModel.twenty,
    WatchModel.goldenEllipse,
    WatchModel.complications,
    WatchModel.officers,
    WatchModel.travelTime,
    WatchModel.Other,
  ],
  audemarsPiguet: [
    WatchModel.royalOak,
    WatchModel.royalOakOffshore,
    WatchModel.royalOakConcept,
    WatchModel.millenary,
    WatchModel.julesAudemars,
    WatchModel.code,
    WatchModel.edwardPiguet,
    WatchModel.hauteJoaillerie,
    WatchModel.promesse,
    WatchModel.tradition,
    WatchModel.Other,
  ],
  richardMille: [
    WatchModel.rm011,
    WatchModel.rm035,
    WatchModel.rm027,
    WatchModel.rm055,
    WatchModel.rm010,
    WatchModel.rm029,
    WatchModel.rm030,
    WatchModel.rm052,
    WatchModel.rm5003,
    WatchModel.rm016,
    WatchModel.Other,
  ],
  paneral: [
    WatchModel.luminor,
    WatchModel.radiomir,
    WatchModel.submersible,
    WatchModel.mareNostrum,
    WatchModel.luminorDue,
    WatchModel.luminorMarina,
    WatchModel.luminorSubmersible,
    WatchModel.luminor1950,
    WatchModel.radiomir1940,
    WatchModel.luminorGMT,
    WatchModel.Other,
  ],
  vacheronConstantin: [
    WatchModel.overseas,
    WatchModel.patrimony,
    WatchModel.traditionnelle,
    WatchModel.historiques,
    WatchModel.fiftysix,
    WatchModel.quai,
    WatchModel.harmony,
    WatchModel.malte,
    WatchModel.dart,
    WatchModel.egerie,
    WatchModel.Other,
  ],
  breguel: [
    WatchModel.classique,
    WatchModel.marine,
    WatchModel.tradition,
    WatchModel.typeXX,
    WatchModel.reineDeNaples,
    WatchModel.heritage,
    WatchModel.laTraditionBreguet,
    WatchModel.classiqueComplications,
    WatchModel.breguetClassiqueDame,
    WatchModel.classiqueGrandesComplications,
    WatchModel.Other,
  ],
  chopard: [
    WatchModel.happyDiamonds,
    WatchModel.luc,
    WatchModel.imperiale,
    WatchModel.milleMiglia,
    WatchModel.alpineEagle,
    WatchModel.happySport,
    WatchModel.classicRacing,
    WatchModel.classic,
    WatchModel.lucXP,
    WatchModel.lucPerpetual,
    WatchModel.Other,
  ],
};

export const BandMaterials = [
  {
    text: WatchBandMaterial.leather,
    img: undefined,
    code: WatchBandMaterial.leather,
  },
  {
    text: WatchBandMaterial.rubber,
    img: undefined,
    code: WatchBandMaterial.rubber,
  },
  {
    text: WatchBandMaterial.snake,
    img: undefined,
    code: WatchBandMaterial.snake,
  },
  {
    text: WatchBandMaterial.stones,
    img: undefined,
    code: WatchBandMaterial.stones,
  },
  {
    text: WatchBandMaterial.stainlessSteel,
    img: undefined,
    code: WatchBandMaterial.stainlessSteel,
  },
  {
    text: WatchBandMaterial.steelNGold,
    img: undefined,
    code: WatchBandMaterial.steelNGold,
  },
  {
    text: WatchBandMaterial.yellowGold,
    img: undefined,
    code: WatchBandMaterial.yellowGold,
  },
];

export const CaseMaterials = [
  {
    text: WatchCaseMaterial.stainlessSteel,
    img: undefined,
    code: WatchCaseMaterial.stainlessSteel,
  },
  {
    text: WatchCaseMaterial.steelNGold,
    img: undefined,
    code: WatchCaseMaterial.steelNGold,
  },
  {
    text: WatchCaseMaterial.yellowGold,
    img: undefined,
    code: WatchCaseMaterial.yellowGold,
  },
  {
    text: WatchCaseMaterial.stones,
    img: undefined,
    code: WatchCaseMaterial.stones,
  },
  {
    text: WatchCaseMaterial.roseGold,
    img: undefined,
    code: WatchCaseMaterial.roseGold,
  },
  {
    text: WatchCaseMaterial.whiteGold,
    img: undefined,
    code: WatchCaseMaterial.whiteGold,
  },
  {
    text: WatchCaseMaterial.goldFilled,
    img: undefined,
    code: WatchCaseMaterial.goldFilled,
  },
  {
    text: WatchCaseMaterial.platinum,
    img: undefined,
    code: WatchCaseMaterial.platinum,
  },
];

export const WatchConditions = [
  {
    text: 'New in Box',
    code: WatchCondition.newInBox,
    img: undefined,
  },
  {
    text: 'Excellent',
    code: WatchCondition.excellent,
    img: undefined,
  },
  {
    text: 'Very Good',
    code: WatchCondition.veryGood,
    img: undefined,
  },
  {
    text: 'Good',
    code: WatchCondition.good,
    img: undefined,
  },
  {
    text: 'Fair',
    code: WatchCondition.fair,
    img: undefined,
  },
  {
    text: 'Not Working',
    code: WatchCondition.nowWorking,
    img: undefined,
  },
];

export const Gender = [
  {
    text: 'Man',
    code: WatchGender.man,
    img: undefined,
  },
  {
    text: 'Woman',
    code: WatchGender.woman,
    img: undefined,
  },
  {
    text: 'Unisex',
    code: WatchGender.unisex,
    img: undefined,
  },
];

export const Conditions = [
  {
    text: 'Yes',
    code: 'yes',
    img: undefined,
  },
  {
    text: 'No',
    code: 'no',
    img: undefined,
  },
];

export const CaratSizeRanges = [
  {
    text: '0 - 0.89 ct.',
    from: 0.1,
    to: 0.89,
  },
  { text: '0.9 - 1.49 ct.', from: 0.9, to: 1.49 },
  {
    text: '1.5 - 1.99 ct.',
    from: 1.5,
    to: 1.99,
  },
  { text: '2 - 2.99 ct.', from: 2, to: 2.99 },
  { text: '3 - 3.99 ct.', from: 3, to: 3.99 },
  { text: '4 ct. or greater', from: 4, to: null },
  { text: 'Not Sure', from: null, to: null },
];

const setSolitaire = 'images/icons/jewelry/ring-settings/ic_soliteire.svg';
const setBridal = 'images/icons/jewelry/ring-settings/ic_bridal_set.svg';
const setThreeStone = 'images/icons/jewelry/ring-settings/ic_stones.svg';
const setHalo = 'images/icons/jewelry/ring-settings/ic_halo.svg';
const setOther = 'images/icons/jewelry/ring-settings/ic_other.svg';

export const itemSettings = [
  {
    code: Styles.Solitaire,
    icon: setSolitaire,
  },
  {
    code: Styles.BridalSet,
    icon: setBridal,
  },
  {
    code: Styles.ThreeStone,
    icon: setThreeStone,
  },
  {
    code: Styles.Halo,
    icon: setHalo,
  },
  {
    code: Styles.Other,
    icon: setOther,
  },
];

const ShapeRound = '/images/icons/jewelry/diamond-shape/ic_round.svg';
const ShapePrincess = '/images/icons/jewelry/diamond-shape/ic_princess.svg';
const ShapeCushion = '/images/icons/jewelry/diamond-shape/ic_cusion.svg';
const ShapeMarquise = '/images/icons/jewelry/diamond-shape/ic_marquise.svg';
const ShapeEmerald = '/images/icons/jewelry/diamond-shape/ic_emerald.svg';
const ShapeOther = '/images/icons/jewelry/diamond-shape/ic_other.svg';

export const itemShapes = [
  {
    text: DiamondShape.Round,
    img: ShapeRound,
    code: DiamondShape.Round,
  },
  {
    text: DiamondShape.Princess,
    img: ShapePrincess,
    code: DiamondShape.Princess,
  },
  {
    text: DiamondShape.Cushion,
    img: ShapeCushion,
    code: DiamondShape.Cushion,
  },
  {
    text: DiamondShape.Marquise,
    img: ShapeMarquise,
    code: DiamondShape.Marquise,
  },
  {
    text: DiamondShape.Emerald,
    img: ShapeEmerald,
    code: DiamondShape.Emerald,
  },
  {
    text: DiamondShape.Other,
    img: ShapeOther,
    code: DiamondShape.Other,
  },
];

export const stoneClassifications = [
  {
    text: CLASSIFICATION.NATURAL,
    img: '/images/icons/caratClassification/natural.png',
    eventText: 'natural',
  },
  {
    text: CLASSIFICATION.LAB_GROWN,
    img: '/images/icons/caratClassification/labGrown.png',
    eventText: 'labGrown',
  },
  {
    text: CLASSIFICATION.GEMSTONE,
    img: '/images/icons/caratClassification/gemstone.png',
    eventText: 'gemstone',
  },
];

export const bucketTypes = ['Loose diamond', 'Ring'];

export const InitialWeightRange = '{ "from": 0, "to": 0, "text": "" }';

const fromSimple = (obj: any) => {
  const ret: any = {};
  Object.keys(obj).forEach((key) => {
    ret[obj[key]] = key;
  });
  return ret;
};

export const FieldEarringsStyleToSimple = {
  jewelry_style_earring_stud: EarringsStyle.Stud,
  jewelry_style_earring_jacket: EarringsStyle.Jacket,
  jewelry_style_earring_central_cluster: EarringsStyle.CentralCluster,
  jewelry_style_earring_blossom_cluster: EarringsStyle.BlossomCluster,
  jewelry_style_earring_chandelier: EarringsStyle.Chandelier,
  jewelry_style_earring_hoops: EarringsStyle.Hoops,
  jewelry_style_earring_huggie: EarringsStyle.Huggie,
  jewelry_style_earring_drop: EarringsStyle.Drop,
  jewelry_style_earring_other: EarringsStyle.Other,
};

export const FieldEarringsStyleFromSimple = fromSimple(FieldEarringsStyleToSimple);

export const FieldBraceletStyleToSimple = {
  jewelry_style_bracelet_tennis: BraceletStyle.Tennis,
  jewelry_style_bracelet_charm: BraceletStyle.Charm,
  jewelry_style_bracelet_slide: BraceletStyle.Slide,
  jewelry_style_bracelet_bangle: BraceletStyle.Bangle,
  jewelry_style_bracelet_link: BraceletStyle.Link,
  jewelry_style_bracelet_other: BraceletStyle.Other,
};

export const FieldBraceletStyleFromSimple = fromSimple(FieldBraceletStyleToSimple);

export const FieldNecklaceStyleToSimple = {
  jewelry_style_necklace_link: NecklaceStyle.Link,
  jewelry_style_necklace_choker: NecklaceStyle.Choker,
  jewelry_style_necklace_collar: NecklaceStyle.Collar,
  jewelry_style_necklace_pendant: NecklaceStyle.Pendant,
  jewelry_style_necklace_riviera: NecklaceStyle.Riviera,
  jewelry_style_necklace_station: NecklaceStyle.Station,
  jewelry_style_necklace_lariat: NecklaceStyle.Lariat,
  jewelry_style_necklace_drop: NecklaceStyle.Drop,
  jewelry_style_necklace_other: NecklaceStyle.Other,
};

export const FieldNecklaceStyleFromSimple = fromSimple(FieldNecklaceStyleToSimple);

export const FieldConditionToSimple = {
  luxury_watch_condition_new_in_box: WatchCondition.newInBox,
  luxury_watch_condition_excellent: WatchCondition.excellent,
  luxury_watch_condition_very_good: WatchCondition.veryGood,
  luxury_watch_condition_good: WatchCondition.good,
  luxury_watch_condition_fair: WatchCondition.fair,
  luxury_watch_condition_not_working: WatchCondition.nowWorking,
};

export const FieldConditionFromSimple = fromSimple(FieldConditionToSimple);

export const FieldBandMaterialToSimple = {
  luxury_watch_band_material_stainless_steel: WatchBandMaterial.stainlessSteel,
  luxury_watch_band_material_leather: WatchBandMaterial.leather,
  luxury_watch_band_material_rubber: WatchBandMaterial.rubber,
  luxury_watch_band_material_snake: WatchBandMaterial.snake,
  luxury_watch_band_material_3_stones: WatchBandMaterial.stones,
  luxury_watch_band_material_steel_and_gold: WatchBandMaterial.steelNGold,
  luxury_watch_band_material_yellow_gold: WatchBandMaterial.yellowGold,
};

export const FieldBandMaterialFromSimple = fromSimple(FieldBandMaterialToSimple);

export const FieldCaseMaterialToSimple = {
  luxury_watch_case_material_stainless_steel: WatchCaseMaterial.stainlessSteel,
  luxury_watch_case_material_steel_and_gold: WatchCaseMaterial.steelNGold,
  luxury_watch_case_material_yellow_gold: WatchCaseMaterial.yellowGold,
  luxury_watch_case_material_3_stones: WatchCaseMaterial.stones,
  luxury_watch_case_material_rose_gold: WatchCaseMaterial.roseGold,
  luxury_watch_case_material_white_gold: WatchCaseMaterial.whiteGold,
  luxury_watch_case_material_gold_filled: WatchCaseMaterial.goldFilled,
  luxury_watch_case_material_platinum: WatchCaseMaterial.platinum,
};

export const FieldCaseMaterialFromSimple = fromSimple(FieldCaseMaterialToSimple);

export const FieldWatchBrandToSimple = {
  luxury_watch_brand_rolex: WatchBrand.Rolex,
  luxury_watch_brand_patek_philippe: WatchBrand.PatekPhilippe,
  luxury_watch_brand_breitling: WatchBrand.Breitling,
  luxury_watch_brand_omega: WatchBrand.Omega,
  luxury_watch_brand_cartier: WatchBrand.Cartier,
  luxury_watch_brand_iwc: WatchBrand.Iwc,
  luxury_watch_brand_tag_heuer: WatchBrand.TagHeuer,
  luxury_watch_brand_audemars_piguet: WatchBrand.AudemarsPiguet,
  luxury_watch_brand_richard_mille: WatchBrand.RichardMille,
  luxury_watch_brand_panerai: WatchBrand.Paneral,
  luxury_watch_brand_vacheron_constantin: WatchBrand.VacheronConstantin,
  luxury_watch_brand_breguet: WatchBrand.Breguel,
  luxury_watch_brand_chopard: WatchBrand.Chopard,
  luxury_watch_brand_other: WatchBrand.Other,
};
export const FieldWatchBrandFromSimple = fromSimple(FieldWatchBrandToSimple);

export const FieldStyleToSimple = {
  jewelry_style_ring_solitaire: Styles.Solitaire,
  jewelry_style_ring_bridal_set: Styles.BridalSet,
  jewelry_style_ring_3_stone: Styles.ThreeStone,
  jewelry_style_ring_halo: Styles.Halo,
  jewelry_style_ring_other: Styles.Other,
};
export const FieldStyleFromSimple = fromSimple(FieldStyleToSimple);

export const FieldShapeToSimple = {
  diamond_shape_round: DiamondShape.Round,
  diamond_shape_princess: DiamondShape.Princess,
  diamond_shape_cushion: DiamondShape.Cushion,
  diamond_shape_marquise: DiamondShape.Marquise,
  diamond_shape_emerald: DiamondShape.Emerald,
  diamond_shape_other: DiamondShape.Other,
};
export const FieldShapeFromSimple = fromSimple(FieldShapeToSimple);

export const FieldShapeToSimpleGemstone = {
  gemstone_shape_round: DiamondShape.Round,
  gemstone_shape_princess: DiamondShape.Princess,
  gemstone_shape_cushion: DiamondShape.Cushion,
  gemstone_shape_marquise: DiamondShape.Marquise,
  gemstone_shape_emerald: DiamondShape.Emerald,
  gemstone_shape_other: DiamondShape.Other,
};
export const FieldShapeFromSimpleGemstone = fromSimple(FieldShapeToSimpleGemstone);

export const FieldColorToSimple = {
  diamond_color_d: DiamondColor.D,
  diamond_color_e: DiamondColor.E,
  diamond_color_f: DiamondColor.F,
  diamond_color_g: DiamondColor.G,
  diamond_color_h: DiamondColor.H,
  diamond_color_i: DiamondColor.I,
  diamond_color_j: DiamondColor.J,
  diamond_color_k: DiamondColor.K,
  diamond_color_l: DiamondColor.L,
  diamond_color_m_z: DiamondColor.MtoZ,
  diamond_color_fancy: DiamondColor.Fancy,
  diamond_color_not_sure: DiamondColor.NotSure,
};
export const FieldColorFromSimple = fromSimple(FieldColorToSimple);

export const FieldColorToSimpleGemstone = {
  gemstone_color_black: GemstoneColor.Black,
  gemstone_color_colorless: GemstoneColor.Colorless,
  gemstone_color_purple: GemstoneColor.Purple,
  gemstone_color_blue: GemstoneColor.Blue,
  gemstone_color_brown: GemstoneColor.Brown,
  gemstone_color_gray: GemstoneColor.Gray,
  gemstone_color_green: GemstoneColor.Green,
  gemstone_color_orange: GemstoneColor.Orange,
  gemstone_color_pink: GemstoneColor.Pink,
  gemstone_color_red: GemstoneColor.Red,
  gemstone_color_white: GemstoneColor.White,
  gemstone_color_yellow: GemstoneColor.Yellow,
  gemstone_color_multicolored: GemstoneColor.Multicolored,
  gemstone_color_na: GemstoneColor.NA,
};
export const FieldColorFromSimpleGemstone = fromSimple(FieldColorToSimpleGemstone);

export const FieldClarityToSimple = {
  diamond_clarity_fl: DiamondClarity.FL,
  diamond_clarity_if: DiamondClarity.IF,
  diamond_clarity_vvs1: DiamondClarity.VVS1,
  diamond_clarity_vvs2: DiamondClarity.VVS2,
  diamond_clarity_vs1: DiamondClarity.VS1,
  diamond_clarity_vs2: DiamondClarity.VS2,
  diamond_clarity_si1: DiamondClarity.SI1,
  diamond_clarity_si2: DiamondClarity.SI2,
  diamond_clarity_i1: DiamondClarity.I1,
  diamond_clarity_i2: DiamondClarity.I2,
  diamond_clarity_i3: DiamondClarity.I3,
  diamond_clarity_not_sure: DiamondClarity.NotSure,
};
export const FieldClarityFromSimple = fromSimple(FieldClarityToSimple);

export const FieldClarityToSimpleGemstone = {
  gemstone_clarity_fl: DiamondClarity.FL,
  gemstone_clarity_if: DiamondClarity.IF,
  gemstone_clarity_vvs1: DiamondClarity.VVS1,
  gemstone_clarity_vvs2: DiamondClarity.VVS2,
  gemstone_clarity_vs1: DiamondClarity.VS1,
  gemstone_clarity_vs2: DiamondClarity.VS2,
  gemstone_clarity_si1: DiamondClarity.SI1,
  gemstone_clarity_si2: DiamondClarity.SI2,
  gemstone_clarity_i1: DiamondClarity.I1,
  gemstone_clarity_i2: DiamondClarity.I2,
  gemstone_clarity_i3: DiamondClarity.I3,
  gemstone_clarity_not_sure: DiamondClarity.NotSure,
};
export const FieldClarityFromSimpleGemstone = fromSimple(FieldClarityToSimpleGemstone);

export const FieldCertificateToSimple = {
  diamond_certificate_none: CertificateType.None,
  diamond_certificate_gia: CertificateType.GIA,
  diamond_certificate_other: CertificateType.Other,
};
export const FieldCertificateFromSimple = fromSimple(FieldCertificateToSimple);

export const FieldEarringsCertificateToSimple = {
  diamond_certificate_gia: EarringsCertificateType.GIA,
  diamond_certificate_igi: EarringsCertificateType.IGI,
  diamond_certificate_gsi: EarringsCertificateType.GSI,
  diamond_certificate_other: EarringsCertificateType.Other,
  diamond_certificate_none: CertificateType.None,
};

export const FieldCertificateToSimpleGemstone = {
  gemstone_certificate_none: CertificateType.None,
  gemstone_certificate_gia: CertificateType.GIA,
  gemstone_certificate_other: CertificateType.Other,
};
export const FieldCertificateFromSimpleGemstone = fromSimple(FieldCertificateToSimpleGemstone);

export const FieldEarringsCertificateFromSimple = fromSimple(FieldEarringsCertificateToSimple);

export const FieldMetaTypeToSimple = {
  jewelry_metal_type_10k_gold: MetalTypes.Under10K,
  jewelry_metal_type_14k_gold: MetalTypes.K14,
  jewelry_metal_type_18k_gold: MetalTypes.K18,
  jewelry_metal_type_22k_gold: MetalTypes.K22,
  //  jewelry_metal_type_24k_gold: MetalTypes.K24,
  jewelry_metal_type_platinum: MetalTypes.Platinum,
  jewelry_metal_type_silver: MetalTypes.Silver,
  jewelry_metal_type_other: MetalTypes.Other,
};
export const FieldMetaTypeFromSimple = fromSimple(FieldMetaTypeToSimple);

export const FieldBrandToSimple = {
  jewelry_brand_tiffany_co: JewelryBrand.Tiffany,
  jewelry_brand_cartier: JewelryBrand.Cartier,
  jewelry_brand_vca: JewelryBrand.vca,
  jewelry_brand_van_cleef_arpels: JewelryBrand.VanCleefArpens,
  jewelry_brand_harry_winston: JewelryBrand.HarryWinston,
  jewelry_brand_david_yurman: JewelryBrand.DavidYurman,
  jewelry_brand_bvlgari: JewelryBrand.Bvlgari,
  jewelry_brand_other: JewelryBrand.Other,
  jewelry_brand_graff: JewelryBrand.Graff,
  'jewelry_brand_david Webb': JewelryBrand.DavidWebb,
  jewelry_brand_verdura: JewelryBrand.Verdura,
  jewelry_brand_buccellati: JewelryBrand.Buccellati,
  jewelry_brand_oscar_heyman: JewelryBrand.OscarHeyman,
  jewelry_brand_chopard: JewelryBrand.Chopard,
};
export const FieldBrandFromSimple = fromSimple(FieldBrandToSimple);
