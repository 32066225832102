import React, { useEffect, useState } from 'react';
import { TextField } from '@worthy-npm/worthy-common-ui-components';
import {
  FormContentNarrow,
  Step,
  StepContainer,
  StepGridContainer,
  NextStepButton,
} from '../../../styles/common';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectItemType, updateTotalCarat } from '../../../slices/submitSlice';
import { StepProps } from '../common';
import StepTitle from '../../stepTitle';
import GA from '../../../data/GA';
import StyledInfoAlert from '../../common/StyledInfoAlert';
import CTAButton from '../../common/ContinueButton';

function TotalWeight({ next, active, stepName, idx, prev, title, stepCaption, props }: StepProps) {
  const [weight, setWeight] = useState<number>(0);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [nextAvailability, setNextAvailability] = useState<boolean>(false);
  const [busy, setBusy] = useState(false);

  const itemType = useAppSelector(selectItemType);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!active) return;
    GA.stepView(stepName);
  }, [active]);

  const validate = (value: string): void => {
    const parsedValue = Number(value);
    if (Number.isNaN(parsedValue) || parsedValue > 50 || parsedValue <= 0) {
      setWeight(0);
      setIsValid(false);
      setNextAvailability(false);
    } else {
      setWeight(parsedValue);
      setIsValid(true);
      setNextAvailability(true);
    }
  };

  // FIXME: The parameter type should be React.KeyboardEvent<HTMLInputElement>,
  // but then we'll need to change the type of e in the `next` function
  const handleEnter = (e: any) => {
    if (e.key === 'Enter') {
      validate(e.target.value);

      if (isValid && nextAvailability) {
        dispatch(updateTotalCarat(weight));
        next(e);
      }
    }
  };

  return (
    <StepContainer data-automation="total-weight-step">
      <Step>
        <StepTitle
          idx={idx}
          prev={prev}
          stepCaption={stepCaption}
          stepTitle={title || 'What is the total carat weight of all diamonds?'}
          stepName={stepName}
          stepNotice={
            itemType === 'Earrings'
              ? 'Please enter the total carat weight of all the diamonds together in one earring'
              : ''
          }
          optionalProps={props || {}}
        />

        <StepGridContainer>
          <FormContentNarrow>
            <TextField
              variant="standard"
              data-automation="total-carat-weight-input"
              inputProps={{ inputMode: 'decimal', type: 'number', step: 0.1, min: 0.1, max: 50.0 }}
              placeholder="0.0 ct."
              error={!isValid}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                validate(e.target.value);
              }}
              onKeyDown={handleEnter}
              sx={{ input: { textAlign: 'center' }, minWidth: '10.3em' }}
            />
          </FormContentNarrow>

          <StyledInfoAlert sx={{ marginTop: 3 }}>
            Tip: Combined carat weight of all diamonds
          </StyledInfoAlert>

          <NextStepButton>
            <CTAButton
              data-automation="total-carat-weight-next-button"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                if (weight > 0 && !busy) {
                  setBusy(true);
                  GA.submitNext(stepName, weight.toString());
                  dispatch(updateTotalCarat(weight));
                  next(e);
                  setTimeout(() => {
                    setBusy(false);
                  }, 150);
                }
              }}
              disabled={!nextAvailability}
            />
          </NextStepButton>
        </StepGridContainer>
      </Step>
    </StepContainer>
  );
}

export default TotalWeight;
