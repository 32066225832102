/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import { IStep2, Wizard2 } from '../components/wizard';
import { useAppDispatch } from '../app/hooks';
import { updateType } from '../slices/submitSlice';
import CommonLayout from './layout/commonLayout';
import Settings from '../components/steps/common/step_setting';
import Shape from '../components/steps/common/step_shape';
import Carat from '../components/steps/common/step_weight';
import StepPhotoUpload from '../components/steps/common/step_photoUpload';
import StepRegistration from '../components/steps/common/step_registration';
import SinglePageFlow from '../components/steps/common/single_page_flow';
import { getExperiment } from '../lib/experiment';
import { loadGraph } from '../lib/wizard';
import StepReject from '../components/steps/common/step_reject';
import StepSubmit from '../components/steps/common/step_submit';

const createSteps = (singlePageFlow = false): IStep2[] => [
  ...(singlePageFlow
    ? [
        {
          comp: SinglePageFlow,
          name: 'SinglePageSubmission',
          caption: '',
          title: 'Tell us about your ring',
          disableBackButton: true,
          staticProgressBar: true,
          disappearFromProgressBar: true,
          disableProgressBar: true,
          props: { fontSize: '42px', fontFamily: 'Playfair Display' },
          exits: {
            next: 'StepSubmit',
          },
        },
      ]
    : []),
  {
    comp: Settings,
    name: 'StepSettings',
    caption: 'Ring Setting',
    title: 'What is the setting of your ring?',
    disableBackButton: true,
    exits: {
      next: 'StepShape',
    },
  },
  {
    comp: Shape,
    name: 'StepShape',
    caption: 'Center Diamond Shape',
    title: 'What is the shape of your center diamond?',
    disableBackButton: false,
    exits: {
      next: 'StepCarat',
    },
  },
  {
    comp: Carat,
    name: 'StepCarat',
    caption: 'Center Diamond Carat',
    title: 'Great! What is the carat weight of your center diamond?',
    exits: {
      next: 'StepSubmit',
    },
  },
  {
    comp: StepSubmit,
    name: 'StepSubmit',
    caption: 'Submit',
    disableBackButton: true,
    exits: {
      next: 'StepRegistration',
      reject: 'StepReject',
    },
  },
  {
    comp: StepReject,
    name: 'StepReject',
    caption: 'Reject',
    disappearFromProgressBar: true,
    disableProgressBar: true,
    disableBackButton: true,
    exits: {
      restart: 'StepSettings',
    },
  },
  {
    comp: StepRegistration,
    name: 'StepRegistration',
    caption: 'Register',
    itemRequired: true,
    skipOnlogin: true,
    disableBackButton: true,
  },
  {
    comp: StepPhotoUpload,
    name: 'StepPhotoUpload',
    caption: 'Upload photos',
    disappearFromProgressBar: true,
    disableProgressBar: true,
    disableBackButton: true,
    exits: {},
    final: true,
  },
];

function RingPage() {
  const dispatch = useAppDispatch();
  const isSinglePageFlow =
    getExperiment('singlePageSubmissionV5') === 'var_1_single_page_submission_singlePage';

  const [isDOMInitialized, setIsDOMInitialized] = React.useState(false);

  const steps = createSteps(isSinglePageFlow);

  useEffect(() => {
    dispatch(updateType('ring'));
    setIsDOMInitialized(true);
  }, [dispatch]);

  const stepsGraph = loadGraph(steps);

  return (
    <CommonLayout>
      <Wizard2 stepsGraph={stepsGraph} />
    </CommonLayout>
  );
}

export default RingPage;
