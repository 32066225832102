import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import _ from 'lodash';
import { Box, Link, Typography } from '@worthy-npm/worthy-common-ui-components';
import { IMask } from 'react-imask';
import { Step, StepContainer, StepGridContainer } from '../../../styles/common';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  IRegistration,
  postRegEstimation,
  registerUser,
  selectFlow,
  selectRegister,
  selectRegisterAPI,
  selectSubmitAPI,
  selectValueCategory,
  updateRegistration,
  updateUserPhoneNumber,
} from '../../../slices/submitSlice';
import { StepProps } from '../common';
import { GoogleSignIn } from '../../google_signin';
import { CookieStore } from '../../../services/cookieStore';
import { LoadingContainer, LoadingOverlay, LoadingDiamondImg } from '../../overlay';
import { IsMobileProp } from '../../../styles/commonPropType';
import StepTitle from '../../stepTitle';
import StepRegistrationForm from './step_registration_form';
import { setItemIdToWindow } from '../../../lib/item';
import GA from '../../../data/GA';

const LogInContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: 50px;
  align-items: center;
`;

const FooterIcon = styled.img`
  margin: 0 8px;
`;

const VSuccess = 'images/icons/v_success.svg';

const GoogleVerified = styled(Box)<IsMobileProp>`
  padding: 1rem 0;
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #00bc35;
  text-align: center;
`;

export function GoogleSignInEnabled() {
  const googleClientID = process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID || '';
  return googleClientID !== '' && CookieStore.getGoogleSigninExp();
}

export const termsConditionsLink = (
  <Link
    color="highlight.main"
    href={`${process.env.REACT_APP_SITE_URL}/about/terms-and-conditions`}
    target="_blank"
  >
    terms & conditions
  </Link>
);

export const privacyPolicyLink = (
  <Link
    color="highlight.main"
    href={`${process.env.REACT_APP_SITE_URL}/about/privacy-policy`}
    target="_blank"
  >
    privacy policy
  </Link>
);

const normalizePhoneNumber = (value: string, mask = true) => {
  if (!value) {
    return '';
  }
  const masked = IMask.createMask({
    mask: '+1 (000) 000-0000',
  });
  masked.resolve(value);
  return mask ? masked.value : masked.unmaskedValue;
};

function StepRegistration({ active, next, stepName, idx, prev, props }: StepProps) {
  const registerAPI = useAppSelector(selectRegisterAPI);
  const registration = useAppSelector(selectRegister);
  const dispatch = useAppDispatch();
  const valueCategory = useAppSelector(selectValueCategory);
  const byImg = useAppSelector(selectFlow);

  const [verificationView, setVerificationView] = useState(false);
  const [loading, setLoading] = useState(false);
  const { itemId } = useAppSelector(selectSubmitAPI);

  // Only show loader to prevent disabling from Google signin button
  // and do it manually after esimation event
  const showLoader = (_state: boolean) => {
    setLoading(true);
  };
  const enableVerification = () =>
    CookieStore.getVerifyPhoneNumberExp() && CookieStore.getGoogleSigninExp();
  const getStepName = () => (verificationView ? 'StepVerification' : stepName);

  useEffect(() => {
    setLoading(registerAPI.loading);
  }, [registerAPI, valueCategory]);

  useEffect(() => {
    if (!active) return;

    GA.registrationStepView(stepName);
  }, [active]);

  setItemIdToWindow(itemId);

  interface IUser {
    id: number;
    phone: string;
    email: string;
  }

  const onSuccessfulGoogleLogin = async (
    user: IUser | undefined,
    isUserLogin: boolean,
    leadId: number,
  ) => {
    // send an event on successful Google
    GA.successfullGoogleRegister(isUserLogin);

    await dispatch(postRegEstimation({ userId: +(user?.id || 0) }));

    setLoading(false);

    // Enable verification if experiment is enabled and user is new and user doesn't have phone number
    if (enableVerification() && !isUserLogin && (!user?.phone || user?.phone === '0000000001')) {
      setVerificationView(true);
    } else {
      next('' as any);
    }
  };

  const onPhoneVerificationSubmit = (data: IRegistration) => {
    if (registerAPI.loading) {
      return;
    }

    const registerWithPhone = { ...registration, phoneNumber: data.phoneNumber };
    dispatch(
      updateUserPhoneNumber({
        data: registerWithPhone,
        callBack: () => {},
        successCallBack: () => {
          next('' as any);
        },
      } as any) as any,
    );
  };

  const onRegistrationSubmit = (data: IRegistration) => {
    if (registerAPI.loading) {
      return;
    }

    dispatch(updateRegistration(data));
    dispatch(
      registerUser({
        data,
        clb: () => {},
        successClb: async (response: any) => {
          await dispatch(postRegEstimation({ userId: response?.user?.id }));
          const consent = data.smsConsent ? data.smsConsent.toString() : 'false';

          GA.registrationSuccess(
            'valueCategory',
            _.camelCase(valueCategory),
            'submissionFlowType',
            byImg ? 'submitByImage' : 'submitByForm',
            'smsConsent',
            consent,
          );
          next('' as any);
        },
      } as any) as any,
    );
  };

  const onSubmit = (data: IRegistration, smsConsent: boolean) => {
    setLoading(true);

    const preparedData = {
      ...data,
      ...{ phoneNumber: normalizePhoneNumber(data.phoneNumber, false) },
    };

    if (verificationView) {
      onPhoneVerificationSubmit(preparedData);
    } else {
      onRegistrationSubmit({ ...preparedData, smsConsent });
    }
  };

  return (
    <LoadingContainer>
      {loading && (
        <LoadingOverlay>
          <LoadingDiamondImg />
        </LoadingOverlay>
      )}

      <StepContainer data-automation={verificationView ? 'verification-step' : 'registration-step'}>
        <Step>
          <StepTitle
            idx={idx}
            prev={prev}
            stepTitle="See how much you can get!"
            stepName="Sign Up"
            stepNotice=""
            stepCaption="Sign Up"
            optionalProps={props || {}}
          />

          <StepGridContainer>
            {verificationView && (
              <GoogleVerified>
                <img src={VSuccess} alt="" width="16px" />
                <span> Google account verified </span>
              </GoogleVerified>
            )}

            <StepRegistrationForm
              onSubmit={onSubmit}
              verificationView={verificationView}
              apiErrorMsg={registerAPI.errorMsg}
              stepName={getStepName()}
              idx={idx}
            />

            {!verificationView && (
              <Box marginTop={3}>
                <LogInContainer>
                  <Typography variant="body1" fontWeight="bold">
                    Already signed up?&nbsp;
                    <Link
                      href={`${process.env.REACT_APP_APP_URL}/signin`}
                      underline="hover"
                      color="highlight.main"
                    >
                      Log in
                    </Link>
                  </Typography>
                </LogInContainer>
              </Box>
            )}

            {GoogleSignInEnabled() && (
              <Box height="76px" marginTop={3} display="flex" justifyContent="center">
                <GoogleSignIn
                  onSuccess={onSuccessfulGoogleLogin}
                  setLoading={showLoader}
                  disabled={verificationView}
                  options={{ width: '350px', size: 'large', shape: 'circle' }}
                />
              </Box>
            )}

            <Typography variant="body1" marginTop="24px" textAlign="center" fontSize="12px">
              By signing up, you agree to the Worthy {termsConditionsLink} and to receive marketing
              communications from Worthy in accordance with our {privacyPolicyLink} (including CCPA
              requirements).
            </Typography>

            <Box display="flex" justifyContent="center" marginTop="24px" marginBottom="25px">
              <FooterIcon src="/images/icons/bbb.svg" />
              <FooterIcon src="/images/icons/secured.svg" />
            </Box>
          </StepGridContainer>
        </Step>
      </StepContainer>
    </LoadingContainer>
  );
}

export default StepRegistration;
