import axios from 'axios';

export type CashForGoldFields = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address: string;
  apartment: string;
  city: string;
  zip: string;
  state: string;
  country: string;
  sms: boolean;
  termsAndConditions: boolean;
  shippingMethod: string;
};

export default class CashForGoldAPI {
  static async submitDataToCashForGold(formValues: CashForGoldFields, ip: string) {
    const data = {
      name_first: formValues.firstName,
      name_last: formValues.lastName,
      phone: formValues.phoneNumber,
      email: formValues.email,
      address_1: formValues.address,
      address_2: formValues.apartment,
      city: formValues.city,
      zip: formValues.zip,
      state: formValues.state,
      country: formValues.country,
      sms: formValues.sms ? 'True' : 'False',
      ip,
      method: formValues.shippingMethod,
    };

    return axios.post(
      `${process.env.REACT_APP_CASH_FOR_GOLD_SUBMIT_URL}`,
      { data },
      {
        headers: {
          Authorization: `Basic ${process.env.REACT_APP_CASH_FOR_GOLD_API_KEY}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }
}
