import React, { useEffect, useState } from 'react';
import {
  Item,
  List,
  Box,
  Typography,
  Icon,
  TextField,
  Button,
} from '@worthy-npm/worthy-common-ui-components';
import { NextStepButton, Step, StepContainer, StepGridContainer } from '../../../styles/common';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectBrand, selectModel, updateModel } from '../../../slices/submitSlice';
import { FormContent, FormWrap, StepProps } from '../common';
import { parametrize } from '../../../lib/commonUtils';
import StepTitle from '../../stepTitle';
import GA from '../../../data/GA';
import { ModelByBrand } from '../../../data/ItemDetails';
import { StyledButton, StyledList } from '../../common/ButtonsList';
import CTAButton from '../../common/ContinueButton';

function StepModel({ next, active, idx, stepName, prev, title, stepCaption, props }: StepProps) {
  const dispatch = useAppDispatch();
  const brand = useAppSelector(selectBrand);
  const model = useAppSelector(selectModel);
  const [busy, setBusy] = useState(false);
  const [customModel, setCustomModel] = useState(model);
  const step = 'model';

  // @ts-ignore
  const modelByBrand = ModelByBrand[brand] || [];

  const modelByBrandSorted = modelByBrand.sort((current: string, nextVal: string) => {
    if (current === 'Other') return 1;
    if (nextVal === 'Other') return -1;
    return current.localeCompare(nextVal);
  });

  useEffect(() => {
    if (!active) return;
    GA.stepView(step);
  }, [active]);

  const onUpdate = (e: any, item: string, wait: number) => {
    if (busy) return;
    setBusy(true);

    dispatch(updateModel(item));
    GA.select({ step, value: item });
    setTimeout(() => {
      next(e);
      setTimeout(() => {
        setBusy(false);
      }, wait);
    }, wait);
  };

  return (
    <StepContainer data-automation="model-material-step">
      <Step>
        <StepTitle
          idx={idx}
          prev={prev}
          stepCaption={stepCaption}
          stepTitle={title || 'What is your watch model?'}
          stepName={stepName}
          stepNotice={brand === 'other' ? 'Please enter model name' : 'Select an option below'}
          optionalProps={props || {}}
        />

        <StepGridContainer>
          <List spacing={2}>
            {brand === 'other' ? (
              <FormWrap>
                <FormContent
                  active
                  data-testid="model-input-form"
                  data-automation="model-input-form"
                  style={{ height: '64px' }}
                >
                  <TextField
                    fullWidth
                    style={{ padding: '2px 4px' }}
                    inputProps={{ maxLength: 25 }}
                    data-automation="model-input"
                    variant="standard"
                    value={customModel}
                    onChange={(e) => {
                      setCustomModel(e.target.value);
                    }}
                    sx={{ input: { textAlign: 'center' } }}
                  />
                </FormContent>
              </FormWrap>
            ) : (
              <StyledList>
                {modelByBrandSorted.map((item: string) => (
                  <StyledButton
                    selected={model === item}
                    data-automation={`model-material-step-item-${parametrize(item)}`}
                    key={item}
                    onClick={(e) => {
                      onUpdate(e, item, 600);
                    }}
                  >
                    <Typography textTransform="capitalize">{item}</Typography>
                  </StyledButton>
                ))}
              </StyledList>
            )}
          </List>

          <NextStepButton>
            {brand === 'other' ? (
              <CTAButton
                data-automation="model-next-button"
                onClick={(e) => {
                  onUpdate(e, customModel, 150);
                }}
                disabled={!customModel.length}
              />
            ) : null}
          </NextStepButton>
        </StepGridContainer>
      </Step>
    </StepContainer>
  );
}

export default StepModel;
