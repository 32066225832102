import React, { useEffect, useState } from 'react';
import { Typography, Stack } from '@worthy-npm/worthy-common-ui-components';
import { StepContainer, StepGridContainer, Step } from '../../../styles/common';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  updateSecondarySubset,
  updateStyle,
  selectStyle,
  selectItemType,
} from '../../../slices/submitSlice';
import { StepProps, StyleI } from '../common';
import { parametrize } from '../../../lib/commonUtils';
import StepTitle from '../../stepTitle';
import GA from '../../../data/GA';
import { StyledButton, StyledList } from '../../common/ButtonsList';

function StepStyle({ active, next, idx, stepName, prev, title, props, stepCaption }: StepProps) {
  const dispatch = useAppDispatch();
  const style = useAppSelector(selectStyle);
  const itemType = useAppSelector(selectItemType);
  const [busy, setBusy] = useState(false);

  const items: StyleI[] = props?.items ?? [];

  useEffect(() => {
    if (!active) return;
    GA.stepView(stepName);
  }, [active]);

  return (
    <StepContainer data-automation="style-step">
      <Step id="step">
        <StepTitle
          idx={idx}
          prev={prev}
          stepTitle={title || `What is the style of your ${itemType.toLowerCase()}?`}
          stepName={stepName}
          stepCaption={stepCaption}
          stepNotice="Select an option below"
          optionalProps={props || {}}
        />
        <StepGridContainer>
          <StyledList>
            {items.map((item: StyleI, index: number) => (
              <StyledButton
                data-automation={`style-step-item-${parametrize(item.code)}`}
                key={item.code}
                selected={style === item.code}
                onClick={(e) => {
                  if (busy) return;
                  setBusy(true);
                  GA.select({ step: stepName, value: item.code });
                  dispatch(updateStyle(item.code));
                  dispatch(updateSecondarySubset(item.secondarySubset));
                  setTimeout(() => {
                    next(e);
                    setTimeout(() => {
                      setBusy(false);
                    }, 600);
                  }, 600);
                }}
              >
                <Stack direction="row" gap={1} alignItems="center">
                  <img
                    src={item.img}
                    alt={item.text}
                    style={
                      index === items.length - 1
                        ? { height: '30px', width: '55px' }
                        : { height: '55px' }
                    }
                  />
                  <Typography variant="body1" textTransform="capitalize">
                    {item.code}
                  </Typography>
                </Stack>
              </StyledButton>
            ))}
          </StyledList>
        </StepGridContainer>
      </Step>
    </StepContainer>
  );
}

export default StepStyle;
