/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-boolean-value */

import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { LinearProgress, Box, Divider, styled } from '@worthy-npm/worthy-common-ui-components';
import { useMobileVersion, useAppDispatch, useAppSelector, useSmallerVersion } from '../app/hooks';
import {
  nextStep,
  prevStep,
  restartStep,
  selectFlow,
  selectSecondarySubset,
  selectSubmitAPI,
  selectUser,
  selectWizard,
  selectWizardHistoryLength,
  submitItem,
  selectCurrentVertexId,
  wizardUseLabel,
  wizardHistoryBack,
  wizardRestart,
  wizardFinish,
  SubmitState,
} from '../slices/submitSlice';
import {
  getProgressStepCount,
  getSubmitIndex,
  getVisibleSteps,
  getProgressStepIndex,
  LabeledDirectedGraph,
} from '../lib/wizard';
import { StepProps } from './steps/common';
import RetryDialog from './retryDialog';
import ItemRejection from './itemRejection';
import GA from '../data/GA';
import { LoadingContainer, LoadingDiamondImg, LoadingOverlay } from './overlay';

const isPreRegRejection = process.env.REACT_APP_PRE_REG_REJECTION === 'true';

export interface IStep {
  comp: React.FC<StepProps>;
  name: string;
  caption: string;
  title?: string;
  hidden?: boolean;
  itemRequired?: boolean;
  skipOnlogin?: boolean;
  secondarySubset?: string;
  key?: string;
  props?: object;
  disableProgressBar?: boolean; // disable progress bar for this step
  disappearFromProgressBar?: boolean; // exclude from progressBar steps calculation
  disableBackButton?: boolean; // disable going back
  staticProgressBar?: boolean; // static line bar
}

export interface IStep2 extends IStep {
  exits?: { [label: string]: string };
  hiddenFns?: [(state: SubmitState) => boolean];
  final?: boolean;
}

interface WizardProps {
  steps: IStep[];
}

const CommonWizardContainer = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const CommonContentContainer = styled('div')`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

const StepContainer = styled('div')`
  max-width: 100%;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  overflow-x: hidden;
`;

const StepStyle = styled('div')<{ active: boolean; left: boolean }>`
  position: absolute;
  justify-content: center;
  transition: opacity 2s, left 1.5s;
  opacity: ${(props: any) => (props.active ? 1 : 0)};
  left: ${(props: any) => (props.active ? '0' : props.left ? '200%' : '-200%')};
  overflow: ${(props: any) => (props.active ? 'auto' : 'hidden')};
  max-height: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
`;

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  '& .MuiSlider-rail': {
    color: theme.palette.highlight.light,
  },
  '& .MuiSlider-track': {
    color: theme.palette.highlight.main,
  },
  '& .MuiSlider-thumb:before': {
    boxShadow: 'none',
  },
  '& .MuiSlider-thumb.Mui-disabled': {
    color: theme.palette.highlight.main,
    boxShadow: 'none',
    borderRadius: theme.shape.borderRadius,
  },
}));

// function Step({ steps, idx, next, prev, props }: any) {
//   const { stepIndex } = useAppSelector(selectWizard);
//   const step = steps[idx];
//   const WrappedComp = step.comp;
//   const active = stepIndex === idx;

//   return (
//     <StepStyle active={active} left={stepIndex < idx}>
//       <WrappedComp
//         idx={idx}
//         stepName={step.name}
//         title={step.title}
//         next={next}
//         prev={prev}
//         props={props}
//         active={active}
//         stepCaption={step.caption}
//       />
//     </StepStyle>
//   );
// }

interface Wizard2Props {
  stepsGraph: LabeledDirectedGraph;
}

function Step2({ step, exit, props }: any) {
  const WrappedComp = step.comp;

  return (
    <StepStyle active={true} left={false}>
      <WrappedComp
        idx={0}
        stepName={step.name}
        title={step.title}
        next={() => exit('next')}
        prev={() => exit('prev')}
        exit={exit}
        props={props}
        active={true}
        stepCaption={step.caption}
      />
    </StepStyle>
  );
}

export function Wizard2({ stepsGraph }: Wizard2Props) {
  const dispatch = useAppDispatch();
  const currentVertexId = useAppSelector(selectCurrentVertexId);
  const { retryIsNeeded } = useAppSelector(selectSubmitAPI);
  const [showAutoReject, setShowAutoReject] = useState(false);
  const { loading } = useAppSelector(selectWizard);

  const currentVertex = currentVertexId
    ? stepsGraph.getVertex(currentVertexId)
    : stepsGraph.getRootVertex();
  if (!currentVertex) {
    throw new Error('Vertex not found in graph');
  }
  const currentStep = currentVertex.step;

  const depth = currentVertexId ? stepsGraph.depthForVertex(currentVertexId) : 0;
  const historyLength = useAppSelector(selectWizardHistoryLength);

  const restart = () => {
    dispatch(wizardRestart({ vertexId: stepsGraph.getRootVertex()?.id || '', stepsGraph }));
  };

  // Do this once to start the wizard
  useEffect(() => {
    restart();
  }, []);

  const disableProgressBar =
    currentVertexId && stepsGraph.getVertex(currentVertexId)?.flags?.disableProgressBar;

  const renderProgressBar = () => {
    return (
      !disableProgressBar &&
      !showAutoReject &&
      !retryIsNeeded && (
        <Box top="-6px" position="relative" zIndex={1200}>
          <StyledLinearProgress
            data-automation="progress-bar"
            value={historyLength + 1}
            max={historyLength + depth}
            sx={{ padding: '6px 0;' }}
          />
        </Box>
      )
    );
  };

  const exit = async (label: string) => {
    if (label === 'prev') {
      GA.previousClick(currentVertex.step.name);
      dispatch(wizardHistoryBack());
    } else if (label === 'next' && currentVertex.flags?.final) {
      dispatch(wizardFinish());
    } else {
      dispatch(wizardUseLabel({ label, stepsGraph }));
    }
  };

  const retrySubmit = async () => {
    const status = await dispatch(submitItem());
    if (status.meta.requestStatus === 'rejected') {
      return { success: false };
    }
    return { success: true };
  };

  return (
    <CommonWizardContainer>
      {renderProgressBar()}
      <CommonContentContainer>
        <StepContainer>
          {loading > 0 && (
            <LoadingContainer>
              {loading && (
                <LoadingOverlay>
                  <LoadingDiamondImg />
                </LoadingOverlay>
              )}
            </LoadingContainer>
          )}
          {showAutoReject ? (
            <ItemRejection />
          ) : (
            <Step2
              step={currentStep}
              key={currentStep.name}
              exit={exit}
              props={{ ...currentStep.props, disableBackButton: currentStep.disableBackButton }}
            />
          )}
        </StepContainer>
      </CommonContentContainer>
      {retryIsNeeded && <RetryDialog retryHook={retrySubmit} restartHook={restart} />}
    </CommonWizardContainer>
  );
}
