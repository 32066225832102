import React from 'react';
import { Box, Icon, IconButton, Typography, styled } from '@worthy-npm/worthy-common-ui-components';
import { OptionalStepPropsI } from './steps/common';

const Wrapper = styled(Box)(({ theme }) => ({
  marginTop: 8,
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}));
const BackButtonWrapper = styled(IconButton)(({ theme }) => ({
  color: theme.palette.highlight.main,
  padding: 0,
  transform: 'rotate(90deg)',
  position: 'absolute',
  left: `-${theme.spacing(1)}`,
}));

const Container = styled(Box)`
  text-align: center;
  @media (min-width: 1200px) {
    max-width: 1200px;
    margin: auto;
    padding: 0 28px;
  }
`;

interface MuiStepTitleProps {
  idx: number;
  prev: React.MouseEventHandler<HTMLElement> | false;
  stepName: string;
  stepTitle: string;
  stepNotice: string;
  stepCaption: string;
  optionalProps: OptionalStepPropsI;
}

function StepTitle({
  idx,
  prev,
  stepName,
  stepTitle,
  stepNotice,
  stepCaption,
  optionalProps,
}: MuiStepTitleProps) {
  const disableBackButton = optionalProps.disableBackButton || false;

  return (
    <Container data-automation="step-title-container">
      <Typography
        color="highlight.main"
        data-automation="step-name"
        variant="body1"
        fontWeight="bold"
      >
        {stepCaption}
      </Typography>
      <Wrapper>
        {!prev || disableBackButton ? null : (
          <BackButtonWrapper
            disableRipple
            data-testid="back-button"
            data-automation={`wizard-prev-button-${idx}`}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              prev(e);
            }}
          >
            <Icon.ExpandMoreShineIcon />
          </BackButtonWrapper>
        )}
        <Typography data-automation="step-title" variant="h2" margin={1}>
          {stepTitle}
        </Typography>
      </Wrapper>
      {stepNotice ? (
        <Typography variant="body1" color="secondary" data-automation="step-notice">
          {stepNotice}
        </Typography>
      ) : null}
    </Container>
  );
}

export default StepTitle;
