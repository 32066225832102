import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';

import { Box, FileInput, Preview, Typography } from '@worthy-npm/worthy-common-ui-components';
import PopupComponent from '../../modal/popupTemplate';
import ButtonsContainer from '../../inputs/buttonsTemplate';
import ModalTemplate from '../../modal/modalTemplate';
import { useAppDispatch, useAppSelector, useMobileVersion } from '../../../app/hooks';
import { PhotoI, selectPhotos, selectSubmitAPI, updatePhotos } from '../../../slices/submitSlice';
import WorthyAPI from '../../../services/worthyAPI';
import { LoadingDiamondImg, LoadingOverlay } from '../../overlay';
import { FILES_MAX_COUNT } from '../../../data/constants';

const pdf = 'images/icons/pdf.svg';
const BackArrow = 'images/icons/back_arrow.svg';

const WrapScroll = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, minmax(140px, 180px));
  grid-auto-rows: 170px;
  max-height: 450px;
  margin: 0 auto;
  justify-content: center;
  justify-items: center;
  grid-gap: 12px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const PreviewWrap = styled(Preview)`
  min-height: 9em;
  max-width: 10em;
  max-height: 12em;
`;

const LargePreview = styled(Preview)(({ img }: Record<string, any>) => ({
  width: '872px',
  height: '556px',
}));

const ActionPlate = styled(Box)`
  display: grid;
  grid-template-columns: repeat(8, 64px) 275px;
  grid-gap: 12px;
  margin-top: 24px;
  .add-more {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
  }
`;

const SmallPreviewWrap = styled(Preview)(({ img }: Record<string, any>) => ({
  maxWidth: '12em',
  maxHeight: '12em',
  div: {
    margin: '5px',
  },
}));

const FileInputWrap = styled(FileInput)`
  padding: 8px 22px;
  width: 10em;
  justify-content: center;
  color: #fff;
`;

const Arrow = styled.span`
  background: url(${BackArrow}) 50% 50% no-repeat;
  top: 50%;
  width: 34px;
  height: 54px;
  position: absolute;
  z-index: 100;
  cursor: pointer;
`;

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 70px;
  margin: 0 0 15px 50px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #999999;
  background: white;
  cursor: pointer;
  &:after {
    position: absolute;
    content: '\\d7'; /* use the hex value here... */
    top: 4px;
    font-size: 19px;
    font-weight: bold;
    color: #1c1c1c;
    line-height: 8px;
    text-align: center;
  }
`;

interface IPhotoModal {
  isOpen: boolean;
  type: 'file' | 'img';
  modalClose: (type: 'file' | 'img') => void;
  popupAdded: boolean;
  clearInput: (type: 'file' | 'img') => void;
  photoSetAction: (
    e: React.ChangeEvent<HTMLInputElement>,
    type: 'file' | 'img',
    isError: boolean,
  ) => void;
  text: { cancel: string; save: string; title: string };
}

const removeImage = async (itemId: string, path: string[]) => {
  return WorthyAPI.removeImages(path, itemId).catch((e) => {
    console.error(e);
    throw new Error(e);
  });
};

function ModalPhotoUpload({
  isOpen,
  modalClose,
  clearInput,
  photoSetAction,
  popupAdded,
  text,
  type,
}: IPhotoModal) {
  const dispatch = useAppDispatch();
  const photos = useAppSelector(selectPhotos);
  const [images, setImages] = useState(photos);
  const { itemId } = useAppSelector(selectSubmitAPI);
  const [isDelete, setDelete] = useState(false);
  const [preview, setPreview] = useState<string | undefined>('');

  const isMobile = useMobileVersion();

  useEffect(() => {
    let filtered: typeof photos;
    if (type.includes('img')) {
      filtered = photos.filter((item) => item.type === 'img');
      setImages(filtered);
    } else {
      filtered = photos.filter((item) => item.type === 'file');
      setImages(filtered);
    }

    if (filtered.length) {
      // @ts-ignore
      setPreview(filtered[0].src);
    } else {
      setPreview(pdf);
    }
  }, [photos, type]);

  const remove = async (photo: PhotoI) => {
    if (!photo.url) {
      return;
    }

    try {
      await removeImage(itemId, [photo.url]);

      const index = photos.findIndex((i) => i.src === photo.src && i.id === photo.id);
      if (index !== -1) {
        const newPhotos = [...photos];

        newPhotos.splice(index, 1);

        dispatch(updatePhotos(newPhotos));
      }

      // dispatch not updating in time so empty length is 1
      if (!images.length || images.length === 1) {
        clearInput(type);
        setDelete(true);
        setTimeout(() => {
          modalClose(type);
          setDelete(false);
        }, 200);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const removeAll = async () => {
    clearInput(type);
    const savedFiles = photos.map(({ url }) => url);

    setDelete(true);
    try {
      await removeImage(itemId, savedFiles);

      const values = photos.filter((item) => item.type !== type);

      dispatch(updatePhotos(values));
      setTimeout(() => {
        modalClose(type);
        setDelete(false);
      }, 200);
    } catch (err) {
      console.error(err);
    }
  };

  const addFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tempFiles = event.target.files;

    if (!tempFiles || tempFiles.length > FILES_MAX_COUNT) {
      return;
    }

    photoSetAction(event, type, false);
  };

  const onError = (e: any) => {
    e.currentTarget.src = pdf;
    e.currentTarget.style.objectFit = 'contain';
  };

  const slide = (back: boolean) => {
    const currIdx = images.findIndex(({ src }) => preview === src);
    let nextIdx: number;
    if (back) {
      nextIdx = currIdx - 1 < 0 ? images.length - 1 : currIdx - 1;
    } else {
      nextIdx = currIdx + 1 > images.length - 1 ? 0 : currIdx + 1;
    }

    const image = images[nextIdx].src;
    setPreview(image);
  };

  return isMobile ? (
    <PopupComponent
      isOpen={isOpen}
      onClose={() => {
        modalClose(type);
      }}
      title={text.title}
      data-automation="popup-component"
    >
      <WrapScroll>
        {isDelete ? (
          <LoadingOverlay>
            <LoadingDiamondImg />
          </LoadingOverlay>
        ) : (
          images.map((item) => (
            <PreviewWrap
              className="preview"
              data-automation="img-preview-component"
              img={item.src}
              key={item.id}
              onClick={() => remove(item)}
              actionHolder
              onError={onError}
            />
          ))
        )}
      </WrapScroll>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginTop="2em"
        columnGap="12px"
      >
        <ButtonsContainer
          disabled={photos.length >= FILES_MAX_COUNT}
          saveCallback={addFile}
          popupAdded={popupAdded}
          deleteCallback={removeAll}
          text={text}
        />
      </Box>
    </PopupComponent>
  ) : (
    <ModalTemplate
      isOpen={isOpen}
      onClose={() => {
        modalClose(type);
      }}
      title={text.title}
    >
      {isDelete ? (
        <LoadingOverlay>
          <LoadingDiamondImg />
        </LoadingOverlay>
      ) : (
        <>
          <Box>
            <Arrow
              style={{ left: '5%', display: images.length > 1 ? 'block' : 'none' }}
              onClick={() => slide(true)}
            />
            <LargePreview img={preview} actionHolder={false} onError={onError} />
            <Arrow
              style={{
                right: '5%',
                transform: 'rotate(0.5turn)',
                display: images.length > 1 ? 'block' : 'none',
              }}
              onClick={() => slide(false)}
            />
          </Box>
          <ActionPlate>
            {images.map((item) => (
              <Box key={item.id}>
                <SmallPreviewWrap
                  img={item.src}
                  key={item.id}
                  onError={onError}
                  actionHolder={false}
                  onPreview={(e) => {
                    setPreview(item.src);
                  }}
                />
                <Circle onClick={() => remove(item)} />
              </Box>
            ))}
            <FileInputWrap
              style={{
                display: images.length >= FILES_MAX_COUNT ? 'none' : 'flex',
                borderColor: photos.length >= FILES_MAX_COUNT ? '#E5E5E5' : '#3A5FE3',
              }}
              disabled={photos.length >= FILES_MAX_COUNT}
              className="add-more"
              accept=".pdf,.jpeg,.png,.jpg"
              status="empty"
              onChange={addFile}
              variant="dashed"
              padding="7px 50px"
              color="paper"
            >
              <Typography
                variant="h6"
                color={photos.length >= FILES_MAX_COUNT ? 'secondary.main' : 'primary.main'}
              >
                +
              </Typography>
            </FileInputWrap>
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              alignItems="center"
              gridColumn="9/-1"
              columnGap="5px"
              width="100%"
              justifySelf="end"
            >
              <ButtonsContainer
                disabled={photos.length >= FILES_MAX_COUNT}
                saveCallback={addFile}
                popupAdded={popupAdded}
                deleteCallback={removeAll}
                text={text}
              />
            </Box>
          </ActionPlate>
        </>
      )}
    </ModalTemplate>
  );
}

export default ModalPhotoUpload;
